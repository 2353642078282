import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    /* User */
    accessToken: null,
    user: null,
    refreshToken: null
  }),


  actions: {
    $reset() {
      this.accessToken = null;
      this.user = null;
      this.refreshToken = null;
    },
  }
});
